import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WebApiService } from '../../webApi.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private controller: string = 'Users';
  private serviceSubDomain: string = environment.serviceSubDomain_login;

  constructor(private webApi: WebApiService) { }

  authenticate(user: { username: string, password: string }) {
    return this.webApi.post(this.serviceSubDomain, `${this.controller}/authenticate`, user);
  }
  refreshToken(token: string) {
    return this.webApi.post(this.serviceSubDomain, `${this.controller}/refresh-token`, { refreshToken: token });
  }
  revokeToken(token: string) {
    return this.webApi.post(this.serviceSubDomain, `${this.controller}/revoke-token`, token);
  }
  UserProfile() {
    return this.webApi.get(this.serviceSubDomain, `${this.controller}/UserProfile`)
  }
  changePassword(userNewData: UserNewData) {
    return this.webApi.put(this.serviceSubDomain, `${this.controller}/ChangePassword`, userNewData);
  }

  editUserProfile(body: UserData) {
    return this.webApi.put(this.serviceSubDomain, `${this.controller}/EditUserProfile`, body);
  }




  forgotPassword(Email: { Email: string }) {
    console.log(Email);
    return this.webApi.post(this.serviceSubDomain, `${this.controller}/ForgotPassword`, Email);
  }

  resetPassword(userNewData: ResetPassword) {
    console.log(userNewData);

    return this.webApi.post(this.serviceSubDomain, `${this.controller}/ResetPassword`, userNewData);
  }
}

export interface UserData {
  nameAr: string;
  nameEn: string;
  phone: string;
  email: string;
  username: string;
  password: string;
  userImageUrl: UserImageUrl;
}


export interface UserImageUrl {
  sizeInBytes: number;
  fileName: string;
  path: string;
  fileBase64: string;
}

export interface UserNewData {
  currentPassword: string
  newPassword: string,
}

export interface UserChangeData {
  newPassword: string,
}


export interface ResetPassword {
  email: string,
  newPassword: string,
  otpCode: string
}