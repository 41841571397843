import { CurrentUserService } from 'src/app/services/current-user.serives';
import { Component } from '@angular/core';
import { LanguageService } from './services/language.serives';
import { SignalRService } from './services/signal-r.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title:string = "circle360-admin-front";
}
