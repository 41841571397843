// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// export const environment = {
//   production: false,
//   defaultLanguage: 'en',
//   protocol: 'https',
//   serviceSubDomain_login: 'localhost:7145',
//   serviceSubDomain_admin: 'localhost:7145',
//   serviceSubDomain_visits: 'localhost:7145',
//   serviceSubDomain_owners: 'localhost:7145',
//   serviceSubDomain_ads: 'localhost:7145',
//   appUrl: '/api/',
//   HUB_URL:'https://localhost:7145/counterhub',
//   googeMapUrl: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB2cuIOUgqEcgXi1KvkXVr7mNa3ym4h7dA"
// };
export const environment = {
  production: false,
  defaultLanguage: 'en',
  protocol: 'https',
  serviceSubDomain_login: 'dev-api.circle360.net',
  serviceSubDomain_admin: 'dev-api.circle360.net',
  serviceSubDomain_visits: 'dev-api.circle360.net',
  serviceSubDomain_owners: 'dev-api.circle360.net',
  serviceSubDomain_ads: 'dev-api.circle360.net',
  appUrl: '/api/',
  HUB_URL:'https://dev-api.circle360.net/counterhub',
  googeMapUrl: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB2cuIOUgqEcgXi1KvkXVr7mNa3ym4h7dA"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
